<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          Taşıma Firmaları
          <span class="d-block text-muted pt-2 font-size-sm"
            >Toplam {{ companies.length }} adet taşıma firması bulunmakta</span
          >
        </h3>
      </div>
      <div class="card-toolbar">
        <router-link
          :to="{ name: 'create-transport-company' }"
          v-slot="{ href }"
        >
          <a :href="href" class="btn btn-dark font-weight-bolder font-size-sm">
            <span class="svg-icon">
              <span class="svg-icon">
                <inline-svg src="media/svg/icons/Code/Plus.svg" />
              </span>
            </span>
            Taşıma Firması Ekle
          </a>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <TransportCompaniesTable :companies="companies" :is-busy="isFetching" />
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import TransportCompaniesTable from "./TransportCompaniesTable.vue";

export default {
  name: "TransportCompaniesPanel",
  components: {
    TransportCompaniesTable
  },
  data() {
    return {
      isFetching: false,
      companies: []
    };
  },
  async created() {
    this.get();
  },
  methods: {
    get() {
      this.isFetching = true;
      ApiService.get("transport-company/listings.req.php")
        .then(({ data }) => {
          this.companies = [...data.data];
          this.isFetching = false;
        })
        .catch(() => {
          this.isFetching = false;
        });
    }
  }
};
</script>
