<template>
  <section>
    <b-table
      class="
        table
        table-head-custom
        table-head-bg
        table-borderless
        table-vertical-center
      "
      thead-class="mb-8"
      show-empty
      empty-text="Listelenecek herhangi bir firma bulunamadı!"
      stacked="lg"
      :items="companies"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :busy="isBusy"
      @filtered="onFiltered"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Yükleniyor...</strong>
        </div>
      </template>
      <template #cell(company)="data">
        <div class="py-2 pl-0">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
              <div
                class="symbol-label"
                style="background-size: contain"
                :style="{
                  backgroundImage: `url(${$MEDIA_PROD_ENV}transport-company/logo/${data.item.company.logo})`,
                }"
              ></div>
            </div>
            <div>
              <span class="text-dark-75 font-weight-bolder font-size-lg mb-1">{{
                data.item.company.name
              }}</span>
              <span class="text-muted font-weight-bold d-block">{{
                data.item.company.commercialTitle
              }}</span>
            </div>
          </div>
        </div>
      </template>
      <template #cell(authorizedPerson)="data">
        <div class="d-flex align-items-center">
          <div>
            <span class="text-dark-75 font-weight-bolder font-size-lg mb-1">{{
              data.item.authorizedPerson.fullName
            }}</span>
            <span class="text-muted font-weight-bold d-block">{{
              data.item.authorizedPerson.phoneNumber
            }}</span>
          </div>
        </div>
      </template>
      <template #cell(actions)="data">
        <router-link
          :to="{
            name: 'edit-transport-company',
            params: { companyId: data.item.id },
          }"
          v-slot="{ navigate }"
        >
          <b-button
            class="btn btn-light btn-hover-primary btn-sm"
            @click="navigate"
          >
            Düzenle
          </b-button>
        </router-link>
      </template>
    </b-table>
    <b-col sm="7" md="6" class="my-1" v-if="isPaginationVisible">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        class="my-0"
        size="md"
      >
      </b-pagination>
    </b-col>
  </section>
</template>

<script>
export default {
  name: "TransportCompaniesTable",
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isPaginationVisible() {
      return this.companies.length > this.perPage;
    },
  },
  data() {
    return {
      fields: [
        {
          key: "company",
          label: "Firma Adı",
          sortable: false,
          class: "pl-7",
        },
        { key: "authorizedPerson", label: "Yetkili Kişi", sortable: false },
        { key: "actions", label: "", sortable: false, class: "text-right" },
      ],
      totalRows: this.companies.length,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, { value: 100, text: "Daha fazla" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
    };
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
