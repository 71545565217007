<template>
  <div>
    <TransportCompaniesPanel />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TransportCompaniesPanel from "./components/TransportCompaniesPanel.vue";

export default {
  name: "TransportCompanyListings",
  components: {
    TransportCompaniesPanel
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "TransportCompanyListings" }
    ]);
  }
};
</script>
